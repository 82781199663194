import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useGeoLocation } from 'lib/Hooks';
import Link from 'components/Link';
import {
  ARTICLE,
  VIDEO,
  RECIPE,
  PRODUCT,
} from 'lib/myNewsConstants';
import classNames from 'classnames';
import styles from './styles.module.scss';

const CONTENT_TYPE_ANCHORS = {
  article: '#saved',
  video: '#saved-videos',
  recipe: '#saved-recipes',
  product: '#wishlist',
};

export function SaveCTA({
  contentId,
  contentType,
  animateCTA = false,
  offset = false,
}) {
  const { isUsa } = useGeoLocation();
  const { isChromeless } = useSelector(({ shared }) => shared);

  const shouldRender = !isChromeless && isUsa && contentId;

  const anchorId = CONTENT_TYPE_ANCHORS[contentType];

  const LinkToSaved = (
    <Link
      to={`/account${anchorId}`}
      data-testid="savedCTA__link"
      stopPropagation={contentType === 'video'} // video will play on click and prevent linking if event isn't caught
    >
      Go to
      {contentType === 'product' ? ' Wishlist' : ' Saved'}
    </Link>
  );

  if (!shouldRender) return null;

  return (
    <div className={classNames(styles.saveCTAContainer, animateCTA ? styles.animate : '', offset ? styles.offset : '')} data-testid="savedCTA">
      <div className={styles.message} data-testid="savedCTA__message">
        {LinkToSaved}
      </div>
    </div>
  );
}


SaveCTA.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf([
    ARTICLE,
    VIDEO,
    RECIPE,
    PRODUCT,
  ]).isRequired,
  animateCTA: PropTypes.bool,
  offset: PropTypes.bool,
};
