import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import classNames from 'classnames';
import './styles.themed.scss';

const TypeIcon = ({
  type,
  url,
  className: propClassName,
  additionalStyles,
  radius = false,
  iconLinkClass,
  target,
}) => {
  if (!type || !['related-article', 'slideshow', 'video', 'shop', 'product'].includes(type)) {
    return null;
  }

  const iconClasses = classNames(
    'typeicon',
    propClassName,
    additionalStyles,
    'icon',
    {
      typeicon__radius: radius,
      'icon-dot': type === 'related-article',
      'icon-gallery': type === 'slideshow',
      'icon-video pointer-events-initial': type === 'video',
      'icon-shop': type === 'shop' || type === 'product',
    },
  );

  if (url) {
    return (
      <Link className={iconLinkClass} to={url} target={target}>
        <span className={iconClasses} data-testid="type-icon" />
      </Link>
    );
  }

  return <span className={iconClasses} data-testid="type-icon" />;
};

TypeIcon.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  url: PropTypes.string,
  radius: PropTypes.bool,
  className: PropTypes.string,
  additionalStyles: PropTypes.string,
  iconLinkClass: PropTypes.string,
  target: PropTypes.string,
};


export default TypeIcon;
